import React, {useEffect} from 'react';
import menu from '../json_config/food_menu.json';
import menuEng from '../json_config/food_menu_en.json';
import ContactUs from './contactus';

const langArr = [{"key": "hindi", "value": "Hindi"}, {"key": "english", "value": "English"}];

export const FoodMenu = () => {
  const [activeTab, setActiveTab] = React.useState("hindi");
  const [menuArr, setMenuArr] = React.useState(menu);

  useEffect(() => {
    setMenuArr(activeTab === "hindi" ? menu : menuEng);
  }, [activeTab]);

  const renderMenuTabel = (foodItem) => {
    return foodItem.map((keyValue) => {
      for (const [day, food] of Object.entries(keyValue)) {
        return (
          <div className='row wd-100 pd-8' key={day}>
            <div className='col display-flex wd-20 text-right text-size-18'>{day}</div>
            <div className='col-2 display-flex wd-70 text-center text-size-18 ml-10'>{food}</div>
          </div>
        );
      }
    });
  };
 
  return (
    <div className='outer-border mt-10'>
      <div className='tabs'>
        {
          langArr.map((lang) => {
            return (
              <div key={lang.key} onClick={() => setActiveTab(lang.key)} className={activeTab === lang.key ? 'tab tabActive' : 'tab'}>{lang.value}</div>
            )
          })
        }
      </div>
      <br />
      <div className='text-center auto-margin text-bold'>
        OUR DAILY TIFFIN MENU
      </div>
      <div className='text-center auto-margin'>Add on Curd/Raita also available on request at Rs.30</div>
      {menuArr.map((menuItem) => {
        for (const [key, value] of Object.entries(menuItem)) {
          return (
            <div key={key.toString()}>
              <div className='row wd-100 text-center mb-10 mt-10 bold-font'>
                <div className='col align-to-center text-size-24'>{key}</div>
              </div>
              {renderMenuTabel(value)}
            </div>
          );
        }
      })}
      {/* <div className='text-center text-size-24 text-wrap pd-10 text-bold'>Sunday Off | Available on Demand With Extra charge</div> */}
      <ContactUs />
      {/* <div className='text-center text-size-30 text-wrap pd-10 text-bold text-primary text-shadow'>Monthly Package</div>
      <div className='text-center text-size-18 text-wrap text-primary text-shadow'>Lunch or Dinner Rs. 2400<br />Lunch and Dinner Rs. 4800</div> */}
      <div className='text-center text-size-18 text-wrap pd-10 text-primary'><marquee>Menu would updated on monthly/quartely or seasonal basis.</marquee></div>

    </div>
  );
};
export default FoodMenu;
