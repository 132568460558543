import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  Link,
  useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from './assets/logo.svg';
import Aboutus from './pages/aboutus';
import Contactus from './pages/contactus';
import Home from './pages/home';
// import Logout from './pages/logout';
import TermsAndConditions from './pages/terms_and_conditions';
// import Register from './pages/register';
// import Login from './pages/login';
import Loader from './components/loader';
import Cart from './pages/cart';
import MakePayment from './pages/make_payment';
import DeliveryAddress from './pages/delivery_address';
import PrivacyPolicy from './pages/privacy_policy';
import SpecialMenu from './pages/special_menu';
import Refresh from './pages/refresh';
import AdminHome from './pages/admin/home';
import AddUser from './pages/admin/addUser';
import EditUser from './pages/admin/editUser';
import { getDeviceType, getUserName, getCustomerRole } from './utils/common';
// import { checkUserIsAuthAction } from './redux_files/actions/users_actions';

export const Menu = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [role, setRole] = useState(undefined);
  const [deviceType, setDeviceType] = useState(getDeviceType());
  const loggedInUserDetails = useSelector(state => state?.usersReducer?.loggedInUserDetails);
  const pageTitle = useSelector(state => state?.pageReducer?.pageTitle);
  const userName = getUserName(loggedInUserDetails);
  const customerRole = getCustomerRole(loggedInUserDetails);

  // const dispatch = useDispatch();
  const handleResize = () => {
    if (window.innerWidth < 600 && isNavOpen) setIsNavOpen(false);
    setDeviceType(getDeviceType());
  }
  useEffect(() => {
    setRole(customerRole);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isNavOpen]);

  // useEffect(() => {
  //   if(userName) dispatch(checkUserIsAuthAction());
  // },[]);

  const location = useLocation();

  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  const loaderState = useSelector(state => state?.loader?.showHideLoaderInState) ?? false;

  return <div>
    <div className='shadow-div'>
      <div className='main-div'>
        <div className='col align-to-left mt-10'>
          <Link to='/'>
            <img src={logo} alt='Shivay Durga' width='150' />
          </Link>
        </div>
        {deviceType !== 'mobile' && (<div className='col-2 align-to-center page-title'>{pageTitle}</div>)}
        <div className='col align-to-right'>
          <div onClick={() => setIsNavOpen(!isNavOpen)} className='menu_icon_div right-float'>
            <div className='menu_icon'></div>
            <div className='menu_icon'></div>
            <div className='menu_icon'></div>
          </div>
        </div>
      </div>
    </div>
    <nav className={`nav ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <div className='row ml-10 mb-10'>
        {userName && (<div className='col wd-70 display-flex'><div className='text-white text-size-18 text-bold pd-l-16'>Hi {userName}</div></div>)}
        <div className={`col display-flex ${userName ? 'wd-20' : 'wd-100'}`}><div className='close_icon' onClick={() => setIsNavOpen(!isNavOpen)}>X</div></div>
      </div>
      <ul>
        <li>
          <Link to='/' className='li_text'>Home</Link>
        </li>
        <li>
          <Link to='/aboutus' className='li_text'>About Us</Link>
        </li>
        <li>
          <Link to='/contactus' className='li_text'>Contact Us</Link>
        </li>
        <li>
          <Link to='/terms' className='li_text'>Terms & Conditions</Link>
        </li>
        <li>
          <Link to='/privacy' className='li_text'>Privacy Policy</Link>
        </li>
        {
          userName ? (
            <>
            {role === "admin" && (
              <li>
                <Link to='/admin' className='li_text'>Admin</Link>
              </li>
            )}
              <li>
                <Link to='/logout' className='li_text'>Logout</Link>
              </li>
            </>
          ) : (
            <>
              {/* <li>
                <Link to='/login' className='li_text'>Login</Link>
              </li>
              <li>
                <Link to='/register' className='li_text'>Register</Link>
              </li> */}
            </>
          )
        }
      </ul>
    </nav>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/aboutus' element={<Aboutus />} />
      <Route path='/contactus' element={<Contactus />} />
      <Route path='/terms' element={<TermsAndConditions />} />
      <Route path='/privacy' element={<PrivacyPolicy />} />
      <Route path='/special-menu' element={<SpecialMenu />} />
      {/* <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} /> */}
      <Route path='/refresh' element={<Refresh />} />
      {/* <Route path='/register' element={loaderState ? <Loader showOrHideLoader={loaderState} /> : <Register />} /> */}
      {
        userName !== undefined && (
          <>
            <Route path='/admin' element={loaderState ? <Loader showOrHideLoader={loaderState} /> : <AdminHome />} />
            <Route path='/adduser' element={loaderState ? <Loader showOrHideLoader={loaderState} /> : <AddUser />} />
            <Route path='/edituser' element={loaderState ? <Loader showOrHideLoader={loaderState} /> : <EditUser />} />
            <Route path='/cart' element={loaderState ? <Loader showOrHideLoader={loaderState} /> : <Cart />} />
            <Route path='/deliveryAddress' element={loaderState ? <Loader showOrHideLoader={loaderState} /> : <DeliveryAddress />} />
            <Route path='/makepayment' element={loaderState ? <Loader showOrHideLoader={loaderState} /> : <MakePayment />} />
          </>
        )
      }
    </Routes>
  </div>
}
