import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageAction } from '../redux_files/actions/page_actions';
import specialMenuImg from '../assets/custom_menu.jpeg';

const SpecialMenu = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Special Menu';
    dispatch(pageAction('Special Menu'));
  });
  return (
    <>
      <div className='container'>
        <div className='aboutus outer-border pd-20'>
        <div className='text-center auto-margin'>
         For order please call or whatsapp us at <a href='tel:+919996212490'>9996212490</a>
          <br />
          Minimum order should be Rs 150, Otherwise you may have to pay delivery charge.<br /> <br />
          </div>
          <img src={specialMenuImg} alt="Special Menu" width="100%" />
        </div>
      </div>
    </>
  );
};

export default SpecialMenu;
