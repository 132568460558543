import React from 'react';

const ContactUs = () => {
  return (
    <div className='text-center text-size-24 pd-20'>
      For order please call us at <a href='tel:+919996212490'>9996212490</a>
    </div>
  );
};
export default ContactUs;
